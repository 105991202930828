@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@font-face {
  font-family: 'Surt';
  src: url('./assets/Surt-Medium.eot');
  src: url('./assets/Surt-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/Surt-Medium.woff2') format('woff2'),
  url('./assets/Surt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
   overflow-y:scroll;
   --color-primary: #ff84ff;
   --color-secondary: #f867f8;
   --color-text: #2c3e50;
}

body, html, button {
  font-family: "Surt", "Roboto", sans-serif;
  font-size: 15px;
  color: var(--color-text);
}

body, html{
  padding: 0;
  margin: 0;
  background-color: var(--color-primary);
  height: 100%;
}

a {
  color: var(--color-secondary);
  text-decoration: none;
}

a:hover{
  color: var(--color-primary)

}

.aframe-shit {
  height: 100%;
  max-height: 100%;
  position: relative;
  transition: max-height 0.5s ease;
}

.aframe-shit a-scene {
  position: absolute;
  max-height: 100vw;

  top: 50%;
  transform: translateY(-50%);

}

.aframe-shit.top{
  max-height: 100vw;
  color: #ffd083;
}

button{
  background-color: unset;
  border: none;
  transition: background-color 0.5s;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
}

button:hover {
  background-color: rgba(255, 208, 131, 0.26);
}

* { box-sizing: border-box }

#contentContainer {
  width: 100%;
  position: fixed;
  transition: top 0.5s;
  top: 100vh;
}

#contentContainer.open {
  position: absolute;
  top: min(80vh, 100vw);

}

#contentCard {
  padding: 20px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  cursor: auto;
  min-height: max(20vh, calc(100vh - 100vw));
  position: relative;
  overflow: hidden;
}

.artistMiniature {
  width: 100px;
  height: 100px;
  grid-area: photo;
  object-fit: cover;
}


.artInfo th, .artInfo td {
  text-align: left;

}

.artInfo th {
  text-transform: uppercase;
}

.artInfo td {
  padding-left: 10px;
}


.infoCard{
  display: grid;
  grid-template-columns: 100px minmax(0, 350px) 1fr minmax(200px, 800px);
  grid-template-rows: auto;
  grid-template-areas:
            "info       info        _ content"
            "divider     divider     _ content"
            "description description _ content"
            "buttons     buttons     _ content"
;
  gap: 10px;
}

.infoCard .buttons {
  grid-area: buttons;
  text-align: right;
}

.artInfo {
  grid-area: info;
  /*min-width: 370px;*/
}

.infoCard::before {
  content: "";
  height: 3px;
  display: block;
  background-color: var(--color-primary);
  grid-area: divider;
}

.artDescription{
  /*border-top: solid 6px var(--color-primary);*/
  grid-area: description;
}

.artContentContainer {
  grid-area: content;
  width: 100%;
}

.artContent {
  height: 0;
  margin-bottom: 10px;
  overflow: hidden;
  padding-top: calc(9 / 18 * 100%);
  position: relative;
}

.artContent .replaceableContent {
  position: absolute;
  top: 0;
  left: calc(1 / 18 * 100%);
  width: calc(16 / 18 * 100%);
  height: 100%;
  overflow: hidden;
}

.artContent .replaceableContent.noarrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}

.artContent .vrContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arrowContainer {
  position: absolute;
  top: 0;
  width: calc(1 / 18 * 100%);
  background-image: url("https://static.newfuture.industra.space/assets/arrow.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
  height: 100%;
}

.arrowContainer:hover {
  opacity: 1;
}

.arrowContainer.right {
  left: calc(17 / 18 * 100%);
}

.arrowContainer.left {
  transform: rotate(180deg);
}

.arrowContainer button {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 30px;
  padding: 0;
  transform: translateY(-50%);
}

.replaceableContent > *{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .infoCard {
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
                    "info info"
                    "content content"
                    "description description"
                    "buttons buttons"
  ;
  }
}

body > .globeControl {
  height: 100%;
}

.globeControl {
  position: relative;
  color: white;
  font-size: 30px;
  font-weight: 500;
}

.globeControl .social {
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.5s;
}

.globeControl .social.hidden {
  opacity: 0;

}

.globeControl .siteName {
  position: absolute;
  top: 20px;
  left: 20px;
  text-transform: uppercase;
}

.globeControl .menu {
  position: absolute;
  top: 20px;
  right: 20px;
  text-transform: uppercase;
}

.globeControl .clickable {
  cursor: pointer;
}

.globeControl .artistLink {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
}

/*@media screen and (max-width: 700px) {
  .globeControl .siteName {
    animation: 6s infinite reverse blink;
  }

  .globeControl .artistLink {
    transform: none;
    left: 20px;
    animation: 6s infinite blink;
  }

  @keyframes blink {
    0% { opacity: 0 }
    10% { opacity: 1; }
    40% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
  }
}
*/

.socialLink {
  width: 50px;
  height: 50px;
  border: 1px white solid;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  padding: 0;
  background-color: white;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  margin-right: 4px;
}

.socialLink.facebook {
  background-image: url('./assets/facebook.svg');
}

.socialLink.twitter {
  background-image: url('./assets/twitter.svg');
}
.socialLink.youtube {
  background-image: url('./assets/youtube.svg');
}

.socialLink.about {
  background-image: url('./assets/info.svg');
}


.socialLink:hover {
  background-color: white;
}

.clickInterceptor{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cardFade-enter {
  opacity: 0.01;
  position: absolute;
  width: 100%;
}

.cardFade-enter.cardFade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.cardFade-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
}

.cardFade-exit.cardFade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms;
}

h1, h2{
  text-transform: uppercase;
  color: var(--color-secondary)
}

.ArtList {
  text-align: center;
}

.SingleArt {
  display: inline-flex;
  height: 240px;
  justify-content: space-between;
  gap: 15px;
  max-width: 1200px;
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.SingleArtist {
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
}

.SingleArtist h2 {
  cursor: pointer;
}

.SingleArtist h2::after {
  content: '';
  width: 0;
  height: 5px;
  display: block;
  background: var(--color-primary);
  transition: 300ms;
}

.SingleArtist h2:hover::after {
  width: 100%;
}

.ArtList h1 {
  text-align: center;
  margin-bottom: 5rem;
}

.SingleArt .artistMiniature {
  height: 100%;
  width: max(200px, 30%)
}

.SingleArt .ArtDescription  {
  height: 100%;
  width: calc(80% - max(230px, 30%));
  overflow: hidden;
}

.SingleArt .buttons {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.SingleArt .ArtDescription h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  text-transform: unset;
  max-width: 500px;

}

.SingleArt .ArtDescription p {
  max-width: 500px;
}

.SingleArt .ArtDescription, .SingleArt .artistMiniature {
  cursor: pointer;
  transition: opacity 0.3s;
}

.SingleArt .ArtDescription:hover, .SingleArt .artistMiniature:hover {
  opacity: 0.7;
}

.SingleArt .ArtDescription h3 {
  margin-top: 0;
}


h1:first-child {
  margin-top: 0;
}

.SingleArt {
  border-bottom: 6px solid var(--color-primary);
}

.artDescription h3 {
  font-size: 1em;
  text-transform: uppercase;
  margin-block-start: 0.1em;
  margin-block-end: 0.5em;
}
