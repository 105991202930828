.ImageContainer, .Poster {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.Poster > * {
    position: relative;
    z-index: 3;
}

.ImageContainer img {
    position: relative;
    max-height: 100%;
    border-radius: 4px;
    z-index: 2;

    transition: opacity 0.5s;
}

.ImageTitle {
    position: absolute;
    padding: 20px;
    background-color: black;
    opacity: 0.7;
    color: white;
    bottom: 0;
    width: 100%;
    z-index: 3;
    transition: opacity 0.3s;
}

.ImageTitle:hover {
    opacity: 0.9;
}

.Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: appear 0.5s;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ImageLoading {
    opacity: 0;
}

.ImageLoaded {
    opacity: 1;
}

.ImageBackground {
    --blur: 2rem;
    filter: blur(var(--blur));
    background-size: cover;
    background-position: center;
    /*background-size: calc(100% + var(--blur));*/
    position: absolute;
    top: calc(-1 * var(--blur));
    left: calc(-1 * var(--blur));
    width: calc(100% + 4 * var(--blur));
    height: calc(100% + 4 * var(--blur));

    margin: calc(-1 * var(--blur));

    transition: background-image 1s;
}
