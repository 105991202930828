.Menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 5;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 5rem;
}

.Menu a {
    color: white;
    transition: color 0.5s;
}

.Menu a::after {
    content: '';
    width: 0;
    height: 0.05em;
    display: block;
    background: white;
    transition: 300ms;
}

.Menu li {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Menu a:hover::after {
    width: 100%;
}

.Menu a:hover {
    color: #ddd;
}

.Menu nav {
    margin: 50px
}

.Menu ol {
    list-style-type: none;
    padding-left: 0;
}


.closeButton {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 0;
    cursor: pointer;

    position: absolute;
    top: 30px;
    right: 30px;

    display: inline-block;
    padding: 0;
    background-image: url('./assets/cross.svg');
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.closeButton:hover {
    background-color: transparent;
}
