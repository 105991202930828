.linkButton {
    display: block;
    color: white;
    background-color: var(--color-secondary);
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    transition: background-color 0.1s;
}

.linkButton:hover {
    color: white;
    background-color: var(--color-primary);
}